var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasData
    ? _c("footer", [
        _vm.$store.state.ui.isVisible.web.footer && _vm.showFormSubscribe
          ? _c(
              "div",
              { staticClass: "container-fluid py-md-5" },
              [
                _c("div", { staticClass: "text-center" }, [
                  _c("div", { staticClass: "h3 mb-2" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formSubscribeNewsletterHeader.title) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.formSubscribeNewsletterHeader.description)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("form-subscribe", {
                  attrs: {
                    "input-disabled": !!_vm.formSubscribeNewsletter,
                    "tracking-params": { i_source: "footer_subscribe" },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "py-0 py-md-5" }, [
          _c(
            "div",
            { staticClass: "container-fluid" },
            [
              _c("base-accordion", [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "base-accordion-item",
                      {
                        staticClass: "col-md-6 mb-md-0 p-0 quick-links",
                        attrs: {
                          header: _vm.$t(
                            _vm.footerState.quickLinks.titleTranslate
                          ),
                          active: _vm.isAccordionActive,
                          disabled: _vm.isAccordionActive,
                        },
                      },
                      [
                        _c(
                          "dl",
                          _vm._l(_vm.quickLinks, function (item, index) {
                            return _c(
                              "dd",
                              { key: index, class: item.break && "break" },
                              [
                                _c(
                                  item.href ? "a" : "router-link",
                                  {
                                    tag: "component",
                                    attrs: {
                                      to: item.to,
                                      href: item.href || item.to,
                                      target: item.target,
                                      rel: item.rel,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          item.text
                                            ? item.text
                                            : _vm.$t(item.textTranslate)
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          !_vm.isMobileApp
                            ? _c(
                                "base-accordion-item",
                                {
                                  staticClass: "col-md-12 mb-md-0 p-0 apps",
                                  attrs: {
                                    header: _vm.$t(
                                      "layouts_footer_col_app_heading"
                                    ),
                                    active: _vm.isAccordionActive,
                                    disabled: _vm.isAccordionActive,
                                  },
                                },
                                [
                                  _c("dl", [
                                    _c(
                                      "dd",
                                      [
                                        _c(
                                          "base-btn",
                                          {
                                            staticClass: "btn-apps",
                                            attrs: {
                                              href: "https://itunes.apple.com/ro/app/vivre/id802901869",
                                              target: "_blank",
                                              rel: "nofollow noopener",
                                              variant: "link",
                                              title: "Apple Store App",
                                            },
                                          },
                                          [
                                            _c("base-icon", {
                                              attrs: { name: "apple" },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "layouts_footer_col_app_link_ios"
                                                    )
                                                  ) +
                                                  "\n                                            "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      [
                                        _c(
                                          "base-btn",
                                          {
                                            staticClass: "btn-apps",
                                            attrs: {
                                              href: "https://play.google.com/store/apps/details?id=com.ro.vivre",
                                              target: "_blank",
                                              rel: "nofollow noopener",
                                              variant: "link",
                                              title: "Google Store App",
                                            },
                                          },
                                          [
                                            _c("base-icon", {
                                              attrs: { name: "android" },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "layouts_footer_col_app_link_android"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.legalInfo
                            ? _c(
                                "base-accordion-item",
                                {
                                  staticClass: "col-md-12 mb-md-0 p-0",
                                  attrs: {
                                    header: _vm.$t(
                                      "layouts_footer_col_legal_heading"
                                    ),
                                    active: _vm.isAccordionActive,
                                    disabled: _vm.isAccordionActive,
                                  },
                                },
                                [
                                  _c(
                                    "dl",
                                    _vm._l(
                                      _vm.legalInfo,
                                      function (item, index) {
                                        return _c("dd", { key: index }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: item.href,
                                                target: item.target,
                                                rel: item.rel,
                                              },
                                            },
                                            [
                                              _c("base-img", {
                                                attrs: {
                                                  lazy: true,
                                                  src: item.image,
                                                  alt: _vm.$t(
                                                    item.textTranslate
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "base-accordion-item",
                      {
                        staticClass: "col-md-3 mb-md-0 p-0",
                        attrs: {
                          header: _vm.$t("layouts_footer_col_social_heading"),
                          active: _vm.isAccordionActive,
                          disabled: _vm.isAccordionActive,
                        },
                      },
                      [
                        _c("dl", [
                          _c(
                            "dd",
                            _vm._l(_vm.socialProfiles, function (url, profile) {
                              return _c(
                                "a",
                                {
                                  key: `socialProfiles-${profile}`,
                                  staticClass: "me-3",
                                  attrs: {
                                    href: url,
                                    target: "_blank",
                                    rel: "nofollow noopener",
                                  },
                                },
                                [
                                  _c("base-icon", { attrs: { name: profile } }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "visually-hidden" },
                                    [_vm._v(_vm._s(profile))]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _vm.publicEmailAddress
                            ? _c("dd", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: `mailto:${_vm.publicEmailAddress}`,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("base-icon", {
                                      attrs: { name: "mail" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.publicEmailAddress)),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$store.state.ui.switch.web.global.phoneNumber &&
                          _vm.$store.state.params.api.publicContactPhone
                            ? _c(
                                "dd",
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/contact" } },
                                    [
                                      _c("base-icon", {
                                        attrs: { name: "phone" },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$store.state.params.api
                                                .publicContactPhone
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "copyright text-center" }, [
          _c("p", { staticClass: "container-fluid" }, [
            _vm._v(
              "\n            © " +
                _vm._s(new Date().getFullYear()) +
                " | " +
                _vm._s(_vm.$t("layouts_footer_copyright")) +
                ".\n        "
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }